

























body {
  &.dark-mode {
    .autocomplete-input {
      /deep/ input {
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23fff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
          right 0.9rem center/8px 10px no-repeat var(--custom-select-bg);
      }

      .list {
        li {
          &.active {
            background-color: var(--menu-item-hover-bg);
          }

          &:hover {
            background-color: var(--menu-item-hover-bg);
          }
        }
      }
    }
  }
}

.autocomplete-input {
  /deep/ input {
    padding-right: calc(1.5em + 0.9rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23b7bec5' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.9rem center/8px 10px no-repeat var(--custom-select-bg);
  }

  .input-group-text {
    background-color: #fff;
    cursor: pointer;
  }

  .list {
    margin-top: calc(1.5em + 0.9rem + 2px);
    position: absolute;
    right: 0;
    left: 0;
    z-index: 100;
    padding-left: 0;
    overflow: auto;
    list-style-type: none;
    color: var(--custom-select-color);
    background: var(--custom-select-bg);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    max-height: 500px;

    li {
      padding: 12px 15px;
      font-size: .875rem;
      cursor: pointer;

      &.active {
        background-color: #dedede;
      }

      &:hover {
        background-color: #dedede;
      }
    }
  }
}
