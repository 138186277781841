









































.page-title-widget {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .enlarged & {
    padding-left: 36px;
  }

  /deep/ .form-group {
    margin-bottom: 0;
  }
}

.button-menu-mobile {
  border: none;
  color: #6e768e;
  width: auto;
  padding: 0;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  margin-right: 16px;
  display: none;

  @media screen and (max-width: 1023.98px) {
    display: block;
    color: #6e768e;
  }

  &.hide {
    display: none;
  }
}


