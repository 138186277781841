



















































.box-header {
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .box-divider {
    font-weight: 500;
    color: #76839a;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.6875rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    opacity: 0.7;
    position: relative;
    text-align: center;

    .dropdown-divider {
      position: absolute;
      width: 100%;
      border-top: 1px solid #dee2e6;
    }

    .box-divider-title {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      padding: 0 20px;
      background-color: var(--body-bg);
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .title {
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: normal;
    width: 50%;
    color: var(--headings-color);
    padding-top: 10px;
  }

  .filters {
    width: 100%;

    &.row {
      width: calc(100% + 24px);
    }
  }
  .chevron {
    width: 42px;
  }

  &.box-styled {
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1.5rem;
  }

  @media screen and (max-width: 1200px) {
    .float-left {
      margin-bottom: 10px;
    }
  }
}
