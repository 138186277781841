































































.date-range {
  .vue-daterange-picker {
    width: 100%;

    /deep/ .daterangepicker {
      top: 40px;
      min-width: unset;

      .calendars {
        flex-wrap: nowrap;

        /* Mobile - 480px, Tablet - 768px */
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
      }

      .calendars-container {
        flex-direction: column;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .date-picker-input {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .placeholder {
      color: #adb5bd;
    }
  }

  .input-group > .input-group-append > .input-group-text {
    line-height: 1.3;
  }
}
